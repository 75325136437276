import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import SwiperSlider from '../../components/SwiperSlidertest';

// import CompanyLogo1 from '../../images/companies/byreesedeluca.png'
// import CompanyLogo2 from '../../images/companies/english-buzz.png'
// import CompanyLogo3 from '../../images/companies/flowcast.png'
// import CompanyLogo4 from '../../images/companies/kindness-culture.png'
// import CompanyLogo5 from '../../images/companies/kristina-webb.png'
// import CompanyLogo6 from '../../images/companies/lead-o-top.png'
// import CompanyLogo7 from '../../images/companies/noxlio.jpeg'
// import CompanyLogo8 from '../../images/companies/rawmax.png'
// import WpShopifyLogo from '../../images/wordpress-shopify-logo.png'
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';





// class IntegrationCompanies extends Component {
//     render() {
//         return (
//             <div className="text-center home-integration-companies-section">
//                 <div className="container">
//                     <Row>
//                         <Col md="5" className="pb-5">
//                             <h5 className="mb-3">Integrates with:</h5>
//                             <img src={WpShopifyLogo} alt="WpShopifyLogo" />
//                         </Col>
//                         <Col md="7" className="pb-5">
//                             <div>
//                                 <h5 className="mb-4">Companies Trust us :</h5>
//                                 <SwiperSlider slidesPerView={4} effect="fade" slides={Slides} spaceBetween={5} slideDeley={2000} />
//                             </div>
//                         </Col>
//                     </Row>
//                 </div>
//             </div>
//         )
//     }
// }


const IntegrationCompanies = ()=>{

const data = useStaticQuery(graphql`

query IntegrationCompaniesImageQuery {
   
    CompanyLogo1: file(absolutePath: {regex: "/images/companies/"}, 
      name: {eq: "byreesedeluca"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    CompanyLogo2: file(absolutePath: {regex: "/images/companies/"}, 
      name: {eq: "english-buzz"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    CompanyLogo3: file(absolutePath: {regex: "/images/companies/"}, 
      name: {eq: "flowcast"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    CompanyLogo4: file(absolutePath: {regex: "/images/companies/"}, 
      name: {eq: "kindness-culture"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    CompanyLogo5: file(absolutePath: {regex: "/images/companies/"}, 
      name: {eq: "kristina-webb"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    CompanyLogo6: file(absolutePath: {regex: "/images/companies/"}, 
      name: {eq: "lead-o-top"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    CompanyLogo7: file(absolutePath: {regex: "/images/companies/"}, 
      name: {eq: "noxlio"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    CompanyLogo8: file(absolutePath: {regex: "/images/companies/"}, 
      name: {eq: "rawmax"}) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, )
      }
    }

    WpShopifyLogo: file(absolutePath: {regex: "/images/"}, 
    name: {eq: "wordpress-shopify-logo"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, )
    }
  }

  }
  


`)

const WpShopifyLogo = getImage(data.WpShopifyLogo)
const CompanyLogo1 = getImage(data.CompanyLogo1)
const CompanyLogo2 = getImage(data.CompanyLogo2)
const CompanyLogo3 = getImage(data.CompanyLogo3)
const CompanyLogo4 = getImage(data.CompanyLogo4)
const CompanyLogo5 = getImage(data.CompanyLogo5)
const CompanyLogo6 = getImage(data.CompanyLogo6)
const CompanyLogo7 = getImage(data.CompanyLogo7)
const CompanyLogo8 = getImage(data.CompanyLogo8)


    const Slides = [
        {
            img: CompanyLogo1
        },
        {
            img: CompanyLogo2
        },
        {
            img: CompanyLogo3
        },
        {
            img: CompanyLogo4
        },
        {
            img: CompanyLogo5
        },
        {
            img: CompanyLogo6
        },
        {
            img: CompanyLogo7
        },
        {
            img: CompanyLogo8
        },
    ]
    return(
        <div className="text-center home-integration-companies-section">
        <div className="container">
            <Row>
                <Col md="5" className="pb-5">
                    <h5 className="mb-3">Integrates with:</h5>
                    <GatsbyImage image={WpShopifyLogo} alt="WpShopifyLogo" />
                    {/* <img src={WpShopifyLogo} alt="WpShopifyLogo" /> */}
                </Col>
                <Col md="7" className="pb-5">
                    <div>
                        <h5 className="mb-4">Companies Trust us :</h5>
                        <SwiperSlider slidesPerView={4} effect="fade" slides={Slides} spaceBetween={5} slideDeley={2000} />
                    </div>
                </Col>
            </Row>
        </div>
    </div>
    )
}

export default IntegrationCompanies
